a.button {
  display: inline-block;
  padding: 10px 20px;
  max-width: 90%;
  box-sizing: border-box;
  text-decoration: none;
  border-radius: 10px;
  background-color: #333;
  border: solid 4px #333;
  color: #FFF;
  text-align: center;
  @include transition(all 0.2s ease);

  [theme=dark] & {
    color: #FFF !important;
  }
}

a.button:hover {
  color: #333;
  background-color: inherit;

  [theme=dark] & {
    background: $global-background-secondary-color-dark;
  }
}

a.button-big {
  padding: 15px 30px;
  font-size: 160%;
  font-weight: bold;
}

a.button-white {
  background-color: inherit;
  border-color: white;
  color: #FFF;
}

a.button-white:hover {
  color: #333;
  border-color: #333;
}

a.button-black {
  background-color: #000;
  color: #FFF;
  border-color: #FFF;
}

a.button-black:hover {
  background-color: #FFF;
  color: #333;
}

a.button-red {
  background-color: #fb0021;
  border: solid 2px #fb0021;
}

a.button-red:visited {
  color: #ffffff;
}

a.button-red:hover {
  background-color: #FFF;
  color: #fb0021;
}

a.big-grey-button {
  display: inline-block;
  max-width: 90%;
  box-sizing: border-box;
  padding: 15px 30px;
  background-color: #666;
  border: solid 2px #555;
  color: #FFF;
  text-align: center;
  text-decoration: none;
  font-size: 160%;
  font-weight: bold;
  border-radius: 20px;
  margin: 50px auto 0;
}

a.big-grey-button:hover {
  background-color: #FFF;
  color: #666;
}